import React from "react";
import Layout from "../components/App/Layout";
import Navbar from "../components/App/Navbar";
import PageBanner from "../components/Common/PageBanner";
import Footer from "../components/App/Footer";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Emobility from "../components/Applications/EMobility";
import { graphql } from "gatsby";
import { Seo } from "../components/App/SEO";

const EmobilityPage = ({ data }) => {
  const download =
    data.download.edges.length > 0
      ? data.download.edges[0]?.node
      : data.download_default.edges[0]?.node;
  const { t } = useTranslation();
  return (
    <Layout>
      <Navbar />
      <PageBanner
        pageTitle={t("Elektromobilität")}
        homePageText="Home"
        homePageUrl="/"
        activePageText={t("Anwendungen")}
      />
      <Emobility download={download} />
      {/*<RelatedServices />*/}
      <Footer />
    </Layout>
  );
};

export default EmobilityPage;

export const pageQuery = graphql`
  query ($language: String) {
    download: allMarkdownRemark(
      filter: {
        frontmatter: { language: { eq: $language } }
        fileAbsolutePath: { regex: "/(/downloads/download-7)/" }
      }
    ) {
      edges {
        node {
          frontmatter {
            link
            attachments {
              publicURL
            }
            title
          }
        }
      }
    }
    download_default: allMarkdownRemark(
      filter: {
        frontmatter: {
          language: { eq: "de" }
          title: { eq: "Abrechnen Elektromobilität" }
        }
        fileAbsolutePath: { regex: "/(/downloads/)/" }
      }
    ) {
      edges {
        node {
          frontmatter {
            link
            attachments {
              publicURL
            }
            title
          }
        }
      }
    }
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const Head = () => (
  <Seo
    title="Elektroautos Laden, Messen und Abrechnen"
    description="Die Abrechnung kann über das Abrechnungsportal von Blockstrom erfolgen – gemeinsam mit dem Stromverbrauch im ZEV oder auch komplett unabhängig davon."
  />
);
