import React from "react";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import { LinkText } from "../../templates/LinkText";

const EMobility = ({ download }) => {
  const { t } = useTranslation();
  return (
    <section className="services-details-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="services-details-desc">
              <h3>{t("Applications - Electromobility - Title - 1")}</h3>
              <p>{t("Applications - Electromobility - Text - 1")}</p>
              <p>{t("Applications - Electromobility - Text - 2")}</p>
              <p>{t("Applications - Electromobility - Text - 3")}</p>

              <h3>{t("Applications - Electromobility - Title - 2")}</h3>

              <p>{t("Applications - Electromobility - Text - 4")}</p>
              <ul>
                <li>{t("Applications - Electromobility - List - 1_1")}</li>
                <li>{t("Applications - Electromobility - List - 1_2")}</li>
                <li>{t("Applications - Electromobility - List - 1_3")}</li>
              </ul>

              <h3>{t("Applications - Electromobility - Title - 3")}</h3>
              <p>{t("Applications - Electromobility - Text - 5")}</p>
                            
              <h3>{t("Applications - Electromobility - Title - 4")}</h3>

              <p>{t("Applications - Electromobility - Text - 6")}</p>
              <ul>
                <li>
                  <a
                    href="https://www.zaptec.com/"
                    title="Zaptec"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Zaptec
                  </a>
                </li>
                <li>
                  <a
                    href="https://easee.com/"
                    title="easee"
                    target="_blank"
                    rel="noreferrer"
                  >
                    easee
                  </a>
                </li>
              </ul>
              <p>{t("Applications - Electromobility - Text - 7")}</p>
              <p>{t("Applications - Electromobility - Text - 8")}</p>
              <ul>
                <li>
                  <a
                    href="https://www.siemens.com/ch/de.html"
                    title="Siemens"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Siemens
                  </a>
                </li>
                <li>
                  <a
                    href="https://etrel.com/"
                    title="Etrel"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Etrel
                  </a>
                </li>
              </ul>
              <p>{t("Applications - Electromobility - Text - 9")}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EMobility;
